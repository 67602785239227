





































































































































































































































/* eslint-disable camelcase */
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SpeechHelper from '@/mixins/SpeechHelper'
import { UnreachableCaseError } from 'ts-essentials'
import ModalTemplate from '../ModalTemplate.vue'
import { AddUserDictionaryRequest, Api, DictionaryDataToEdit, DictionaryInfo, PhonemesInfo, UserModel, DictionaryInfoEdit, currentDictionaryObjectVersion } from '../../api'
import { getLocaleFromUnderscored } from '../../i18n/locale-map'
import WordVariantSelector from './WordVariantSelector.vue'
import EditWordPhonics from './EditWordPhonics.vue'
import EditWordDetails from './EditWordDetails.vue'
import EditWordDictionary from './EditWordDictionary.vue'
import EditWordThesaurus from './EditWordThesaurus.vue'
import EditWordElements from './EditWordElements.vue'

type DictionaryTabs = 'details' | 'dictionary' | 'phonics' | 'thesaurus'

const emptyDef = {
  id: 0,
  user_id: 1,
  word: '',
  word_class: null,
  locale: 'en_GB' as const,
  errors: [],
  definitions: [],
  difficulty_index: 0,
  morphemes: [],
  syllables: [],
  sentences: [],
  phonics: [],
  synonyms: [],
  antonyms: [],
  image: null,
  audio: null,
  parent_word: null,
  new_audio: undefined,
  new_image: undefined,
  new_variant_audio: undefined,
  flags: [],
  approved: false,
  hidden: false,
  elements: [],
  as_in: null,
  ipa_definition: null,
  variant_type: null,
  variant_phonics: null,
  variant_audio: null,
  audio_versions: {},
  new_audio_versions: undefined,
  is_valid: false,
  difficulty: {
    calculated_from_scheme: {

    }
  },
  object_version: currentDictionaryObjectVersion
}

@Component({ components: { EditWordPhonics, EditWordDetails, EditWordDictionary, EditWordThesaurus, EditWordElements, ModalTemplate, WordVariantSelector } })
export default class EditWordDataModal extends Mixins(SpeechHelper) {
  /**
   * Dictionary data to edit
   */
  @Prop({ required: true }) readonly worddata!: DictionaryInfo

  /**
  * Toggle whether user can create a custom definition from this component
  */
  @Prop({ default: false }) readonly allowAddCustom!: boolean

  /**
   * Abstracted user state. Pass in the current user from e.g. vuex store
   */
  @Prop({ required: true }) readonly storedUser!: UserModel

  /**
   * Function that creates deep copies of an object. Recommend using lodash's cloneDeep
   */
  @Prop({ required: true }) readonly cloneDeep!: <T>(obj: T) => T

  /**
   * Function that debounces input before executing another function. Recommend using loDash's debounce
   */
  @Prop({ required: true }) readonly debounce!: (func: (...args: any[]) => Promise<any>, delay: number) => any

  /**
   * Function that checks equality between values, capable of considering nested objects. Recommend using lodash's isEqual
   */
  @Prop({ required: true }) readonly isEqual!: (obj: any, obj2: any) => boolean

  /**
   * Static copy of original values to track changes against
   */
  private origDictionaryInfo!: DictionaryInfo

  /**
   * Static copy of original user values to track changes against
   */
  private origUserDictionaryInfo!: DictionaryInfo | null

  private tab: DictionaryTabs = 'dictionary'

  private personalDictionary: boolean = false
  private editingTitle: boolean = false
  private loadedWordInsteadOfCreating: boolean = false

  private isMounted: boolean = false

  /**
   * Working copy of definition to share and mutate
   */
  private dictionaryInfo: DictionaryInfoEdit = emptyDef

  /**
   * Working copy of user definition to share and mutate
   */
  private userDictionaryInfo: DictionaryInfoEdit | null = emptyDef

  private phonemesData: PhonemesInfo[] | null = null

  private loading: boolean = false

  private wordDataVariantModal: boolean = false
  private homographOptionsModal: DictionaryInfo[] = []

  new_fields = {
    new_image: undefined,
    new_variant_audio: undefined,
    new_audio_versions: undefined
  }

  created () {
    // need to explicitly set the upload fields for reactivity to work.
    if (this.worddata.parent_word) {
      this.dictionaryInfo = this.cloneDeep({ ...this.worddata.parent_word, ...this.new_fields })
      this.origDictionaryInfo = this.cloneDeep({ ...this.worddata.parent_word, ...this.new_fields })
      this.userDictionaryInfo = this.cloneDeep({ ...this.worddata, ...this.new_fields })
      this.origUserDictionaryInfo = this.cloneDeep({ ...this.worddata, ...this.new_fields })
      this.personalDictionary = true
    } else {
      this.dictionaryInfo = this.cloneDeep({ ...this.worddata, ...this.new_fields })
      this.origDictionaryInfo = this.cloneDeep({ ...this.worddata, ...this.new_fields })
      this.userDictionaryInfo = null
      this.origUserDictionaryInfo = null
    }
  }

  async loadUsername () {
    if (!this.userDictionaryInfo || !this.origUserDictionaryInfo) {
      throw new Error('Properties not initialised')
    }

    this.worddata.owner_username = await Api.getOwnerNameOfWord(this.worddata.id)

    this.userDictionaryInfo.owner_username = this.worddata.owner_username
    this.origUserDictionaryInfo.owner_username = this.worddata.owner_username
  }

  async mounted () {
    console.log(this.worddata)
    this.getPhonemes()

    if (this.worddata.user_id !== 1 && this.worddata.user_id !== this.storedUser.id && this.worddata.owner_username === undefined) {
      await this.loadUsername()
    }
    this.isMounted = true
  }

  async getPhonemes () {
    try {
      this.phonemesData = await Api.getPhonemes(this.wordLocale.underscored)
    } catch (err) {
      this.$buefy.toast.open({ message: 'Could not retrieve the phonemes', position: 'is-bottom', type: 'is-danger', duration: 3000 })
    }
  }

  async addCustomDefinition () {
    // todo - look for userWordrride for user
    let userWord: DictionaryInfoEdit | null = null
    try {
      userWord = await Api.getMyVersionOfWord(this.dictionaryInfo.id)
    } catch (err) {
      // user does not have a definition - no problem
    }

    if (userWord) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Loaded your saved definition',
        position: 'is-bottom',
        type: 'is-success'
      })

      this.loadedWordInsteadOfCreating = true
    } else {
      userWord = this.makeUserWord()
    }

    // need to explicitly set the upload fields for reactivity to work.
    userWord = { ...userWord, new_image: undefined, new_variant_audio: undefined }

    this.origDictionaryInfo = userWord.parent_word!
    this.origUserDictionaryInfo = userWord
    this.dictionaryInfo = this.cloneDeep(userWord.parent_word!)
    this.userDictionaryInfo = this.cloneDeep(userWord)
    this.$nextTick(() => {
      this.personalDictionary = true
    })
  }

  makeUserWord () {
    const def = this.cloneDeep(this.worddata) // start with base word
    def.id = 0
    def.user_id = this.storedUser.id
    def.parent_word = this.cloneDeep(this.origDictionaryInfo)

    return def
  }

  get wordLocale () {
    return getLocaleFromUnderscored(this.worddata.locale)
  }

  close () {
    // check if user has pending changes
    if (this.needsSave) {
      this.$buefy.dialog.confirm({
        title: 'Unsaved Changes',
        message: 'You have made some changes but haven\'t saved your work. Are you sure you want to close?',
        confirmText: 'Close',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.$emit('close')
      })
    } else {
      this.$emit('close')
    }
  }

  submit () {
    if (!this.storedUser || !this.needsSave) {
      return
    }

    if ((this.storedUser.superuser || this.storedUser.dictionary_editor) && !this.personalDictionary) {
      this.saveRootWord()
    } else if (this.personalDictionary) {
      if (this.userDictionaryInfo?.user_id !== this.storedUser.id) {
        this.$buefy.dialog.confirm({
          title: 'Confirm edit definition',
          message: `You are about to edit a definition that belongs to @${this.userDictionaryInfo?.owner_username}. Are you sure you would like to make this change?`,
          confirmText: 'Confirm',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this.saveUserWord()
        })
      } else {
        this.saveUserWord()
      }
    }
  }

  async saveRootWord () {
    if (this.personalDictionary || this.userDictionaryInfo !== null) {
      return // user shouldn't be saving this while viewing personal definitions
    }

    const phonicsEdit = this.$refs.phonicsEdit as EditWordPhonics
    const dictionaryEdit = this.$refs.dictionaryEdit as EditWordDictionary

    if (this.dictionaryInfo.phonics.length > 0 && !phonicsEdit.phonicsComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid phonics data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'phonics'

      return
    }

    if (this.dictionaryInfo.morphemes.length > 0 && !dictionaryEdit.isMorphemeComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid morpheme data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    if (!this.dictionaryEditView.areSentencesComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid sentence data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    if (!this.dictionaryEditView.areDefinitionsComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid definition data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    this.loading = true

    if (this.isNewWord) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, user_id: _user_id, ...fields } = this.dictionaryInfo
      const newWord = await Api.addDictionaryWord(fields)

      this.$emit('root-word-created', newWord)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, image: _image, ...fields } = this.dictionaryInfo
      const updatedWord = await Api.updateDictionaryWord(this.worddata.id, { ...fields, variant_type: fields.variant_type !== this.worddata.variant_type ? fields.variant_type : undefined, variant_phonics: fields.variant_phonics ?? undefined })

      this.$emit('root-word-updated', updatedWord)
    }

    this.loading = false

    this.$emit('close')
  }

  async saveUserWord () {
    if (!this.personalDictionary || this.userDictionaryInfo === null) {
      return // user shouldn't be saving this while viewing root definitions
    }

    if (this.userDictionaryInfo.phonics.length > 0 && !this.phonicsEditView.phonicsComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid phonics data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'phonics'

      return
    }

    if (this.userDictionaryInfo.morphemes.length > 0 && !this.dictionaryEditView.isMorphemeComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid morpheme data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    if (!this.dictionaryEditView.areSentencesComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid sentence data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    if (!this.dictionaryEditView.areDefinitionsComplete) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Invalid definition data',
        position: 'is-bottom',
        type: 'is-danger'
      })

      this.tab = 'dictionary'

      return
    }

    if (!this.userDictionaryInfo.parent_word) {
      return
    }

    if (this.isNewUserWord) {
      // in this branch dictionaryInfo = worddata and userDictionaryInfo is a generated shell word
      const wordToSave: AddUserDictionaryRequest = {
        word: this.userDictionaryInfo.word,
        locale: this.userDictionaryInfo.locale,
        parent_id: this.userDictionaryInfo.parent_word.id,
        errors: this.dictionaryEditView.doesUserFieldMatchRoot('errors') ? null : this.userDictionaryInfo.errors,
        definitions: this.dictionaryEditView.doesUserFieldMatchRoot('definitions') ? null : this.userDictionaryInfo.definitions,
        morphemes: this.dictionaryEditView.doesUserFieldMatchRoot('morphemes') ? null : this.userDictionaryInfo.morphemes,
        syllables: this.dictionaryEditView.doesUserFieldMatchRoot('syllables') ? null : this.userDictionaryInfo.syllables,
        sentences: this.dictionaryEditView.doesUserFieldMatchRoot('sentences') ? null : this.userDictionaryInfo.sentences,
        phonics: this.phonicsEditView.doesUserFieldMatchRoot('phonics') ? null : this.userDictionaryInfo.phonics,
        difficulty_index: this.dictionaryInfo.difficulty_index,
        antonyms: this.thesaurusEditView.doesUserFieldMatchRoot('antonyms') ? null : this.userDictionaryInfo.antonyms,
        synonyms: this.thesaurusEditView.doesUserFieldMatchRoot('synonyms') ? null : this.userDictionaryInfo.synonyms,
        new_image: this.userDictionaryInfo.new_image,
        new_audio_versions: this.userDictionaryInfo.new_audio_versions
      }
      const newWord = await Api.addUserDictionaryWord(wordToSave)

      this.$emit('user-word-created', newWord)
    } else {
      // in this branch userDictionaryInfo = worddata and dictionaryInfo = worddata.parent_word
      const wordToSave: DictionaryDataToEdit = {
        word: this.dictionaryInfo.word, // word... word never changes,
        locale: this.dictionaryInfo.locale, // neither does locale
        parent_id: this.dictionaryInfo.id, // or the parent id
        difficulty_index: this.dictionaryInfo.difficulty_index, // or difficulty index
        word_class: this.dictionaryEditView.hasUserFieldChanged('word_class') ? (this.dictionaryEditView.doesUserFieldMatchRoot('word_class') ? undefined : this.userDictionaryInfo.word_class) : undefined,
        errors: this.dictionaryEditView.hasUserFieldChanged('errors') ? (this.dictionaryEditView.doesUserFieldMatchRoot('errors') ? null : this.userDictionaryInfo.errors) : undefined,
        definitions: this.dictionaryEditView.hasUserFieldChanged('definitions') ? (this.dictionaryEditView.doesUserFieldMatchRoot('definitions') ? null : this.userDictionaryInfo.definitions) : undefined,
        morphemes: this.dictionaryEditView.hasUserFieldChanged('morphemes') ? (this.dictionaryEditView.doesUserFieldMatchRoot('morphemes') ? null : this.userDictionaryInfo.morphemes) : undefined,
        syllables: this.dictionaryEditView.hasUserFieldChanged('syllables') ? (this.dictionaryEditView.doesUserFieldMatchRoot('syllables') ? null : this.userDictionaryInfo.syllables) : undefined,
        sentences: this.dictionaryEditView.hasUserFieldChanged('sentences') ? (this.dictionaryEditView.doesUserFieldMatchRoot('sentences') ? null : this.userDictionaryInfo.sentences) : undefined,
        phonics: this.phonicsEditView.hasUserFieldChanged('phonics') ? (this.phonicsEditView.doesUserFieldMatchRoot('phonics') ? null : this.userDictionaryInfo.phonics) : undefined,
        antonyms: this.thesaurusEditView.hasUserFieldChanged('antonyms') ? (this.thesaurusEditView.doesUserFieldMatchRoot('antonyms') ? null : this.userDictionaryInfo.antonyms) : undefined,
        synonyms: this.thesaurusEditView.hasUserFieldChanged('antonyms') ? (this.thesaurusEditView.doesUserFieldMatchRoot('synonyms') ? null : this.userDictionaryInfo.synonyms) : undefined,
        new_image: this.userDictionaryInfo.new_image,
        elements: this.elementsEditView.hasUserFieldChanged('elements') ? (this.detailsEditView.doesUserFieldMatchRoot('elements') ? null : this.userDictionaryInfo.elements) : undefined,
        as_in: this.dictionaryEditView.hasUserFieldChanged('as_in') ? (this.dictionaryEditView.doesUserFieldMatchRoot('as_in') ? undefined : this.userDictionaryInfo.as_in) : undefined,
        ipa_definition: this.phonicsEditView.hasUserFieldChanged('ipa_definition') ? (this.phonicsEditView.doesUserFieldMatchRoot('ipa_definition') ? undefined : this.userDictionaryInfo.ipa_definition) : undefined,
        variant_type: this.detailsEditView.hasUserFieldChanged('variant_type') ? (this.detailsEditView.doesUserFieldMatchRoot('variant_type') ? undefined : this.userDictionaryInfo.variant_type) : undefined,
        variant_phonics: this.detailsEditView.hasUserFieldChanged('variant_phonics') ? (this.detailsEditView.doesUserFieldMatchRoot('variant_phonics') ? null : this.userDictionaryInfo.variant_phonics) : undefined,
        new_variant_audio: this.userDictionaryInfo.new_variant_audio,
        new_audio_versions: this.userDictionaryInfo.new_audio_versions
      }
      const updatedWord = await Api.updateDictionaryWord(this.userDictionaryInfo.id, wordToSave)

      this.$emit('user-word-updated', updatedWord)
    }
  }

  public populateWord () {
    this.changeWordVariant()
    this.wordDataVariantModal = true
  }

  public async changeWordVariant () {
    if (!this.worddata) {
      return
    }

    const homographs = await Api.getHomographsOfWord(this.worddata.word, 'en_GB')

    const o = homographs.filter((obj1, i, arr) =>
      arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
    )

    this.homographOptionsModal = o
  }

  wordVariantSelected (variant: DictionaryInfo) {
    this.dictionaryInfo = this.selectiveCopy(this.dictionaryInfo, variant)
    this.wordDataVariantModal = false
  }

  public selectiveCopy (obj1: DictionaryInfoEdit, obj2: DictionaryInfo): DictionaryInfoEdit {
    return {
      antonyms: obj1.antonyms,
      synonyms: obj1.synonyms,
      approved: obj1.approved,
      as_in: obj1.as_in ?? obj2.as_in,
      audio_versions: obj2.audio_versions, // these are distinctly different
      definitions: obj1.definitions.length === 0 ? [...obj2.definitions] : obj1.definitions,
      difficulty: obj1.difficulty ?? obj2.difficulty,
      difficulty_index: obj1.difficulty_index === 0 ? obj2.difficulty_index : obj1.difficulty_index,
      elements: obj1.elements.length === 0 ? obj2.elements.map(e => ({ ...e, id: -1 })) : obj1.elements,
      errors: obj1.errors.length === 0 ? [...obj2.errors] : obj1.errors,
      flags: obj1.flags.length === 0 ? [...obj2.flags] : obj1.flags,
      hidden: obj1.hidden,
      id: obj1.id,
      image: obj1.image,
      ipa_definition: obj1.ipa_definition ?? obj2.ipa_definition,
      is_valid: obj1.is_valid,
      locale: obj1.locale,
      morphemes: obj1.morphemes.length === 0 ? [...obj2.morphemes] : obj1.morphemes,
      object_version: obj1.object_version,
      parent_word: obj1.parent_word,
      phonics: obj1.phonics.length === 0 ? [...obj2.phonics] : obj1.phonics,
      sentences: obj1.sentences.length === 0 ? [...obj2.sentences] : obj1.sentences,
      syllables: obj1.syllables.length === 0 ? [...obj2.syllables] : obj1.syllables,
      user_id: obj1.user_id,
      variant_audio: obj1.variant_audio,
      variant_phonics: obj1.variant_phonics,
      variant_type: obj1.variant_type,
      word: obj1.word,
      word_class: obj1.word_class
    }
  }

  switchToDefinition () {
    this.$emit('selected-dictionary', this.personalDictionary ? this.userDictionaryInfo : this.dictionaryInfo)
  }

  get isNewUserWord () {
    return this.userDictionaryInfo && this.userDictionaryInfo.id === 0
  }

  get isNewWord () {
    return this.dictionaryInfo.id === 0
  }

  get needsSave () {
    if (!this.isMounted) {
      return false
    }

    if (this.personalDictionary) {
      return this.hasUserWordChanged
    } else {
      return this.hasRootWordChanged || this.isNewWord
    }
  }

  get hasUserWordChanged () {
    if (!this.isMounted) {
      return false
    }

    return this.phonicsEditView.hasUserFieldChanged('phonics') || this.dictionaryEditView.hasUserFieldChanged('definitions') ||
      this.dictionaryEditView.hasUserFieldChanged('sentences') || this.dictionaryEditView.hasUserFieldChanged('morphemes') ||
      this.dictionaryEditView.hasUserFieldChanged('errors') || this.thesaurusEditView.hasUserFieldChanged('synonyms') ||
      this.thesaurusEditView.hasUserFieldChanged('antonyms') || this.detailsEditView.hasUserFieldChanged('new_image') ||
      this.dictionaryEditView.hasUserFieldChanged('syllables') ||
      this.phonicsEditView.hasUserFieldChanged('flags') || this.dictionaryEditView.hasUserFieldChanged('as_in') ||
      this.phonicsEditView.hasUserFieldChanged('new_variant_audio') || this.phonicsEditView.hasUserFieldChanged('variant_type') ||
      this.phonicsEditView.hasUserFieldChanged('variant_phonics') || this.elementsEditView.hasUserFieldChanged('elements') ||
      this.detailsEditView.hasUserFieldChanged('new_audio_versions')
  }

  get hasRootWordChanged () {
    if (!this.isMounted) {
      return false
    }

    return this.phonicsEditView.hasRootFieldChanged('phonics') || this.dictionaryEditView.hasRootFieldChanged('definitions') ||
      this.dictionaryEditView.hasRootFieldChanged('sentences') || this.dictionaryEditView.hasRootFieldChanged('morphemes') ||
      this.dictionaryEditView.hasRootFieldChanged('errors') || this.thesaurusEditView.hasRootFieldChanged('synonyms') ||
      this.thesaurusEditView.hasRootFieldChanged('antonyms') || this.detailsEditView.hasRootFieldChanged('new_image') ||
      this.detailsEditView.hasRootFieldChanged('difficulty_index') ||
      this.dictionaryEditView.hasRootFieldChanged('syllables') || this.phonicsEditView.hasRootFieldChanged('flags') ||
      this.dictionaryEditView.hasRootFieldChanged('approved') || this.dictionaryInfo.word !== this.origDictionaryInfo.word ||
      this.dictionaryEditView.hasRootFieldChanged('as_in') || this.phonicsEditView.hasRootFieldChanged('ipa_definition') ||
      this.phonicsEditView.hasRootFieldChanged('new_variant_audio') || this.phonicsEditView.hasRootFieldChanged('variant_type') ||
      this.phonicsEditView.hasRootFieldChanged('variant_phonics') ||
      this.elementsEditView.hasRootFieldChanged('elements') || this.dictionaryEditView.hasRootFieldChanged('word_class') ||
      this.detailsEditView.hasRootFieldChanged('new_audio_versions')
  }

  get canSave () {
    if (this.personalDictionary) {
      return true
    } else if (this.userDictionaryInfo !== null) {
      return false
    } else if (this.storedUser.superuser || this.storedUser.dictionary_editor) {
      return true
    } else {
      return false
    }
  }

  get canSwitchDefinition () {
    if (this.personalDictionary && this.worddata.id !== this.userDictionaryInfo!.id && this.userDictionaryInfo!.id !== 0) {
      return true
    }

    if (!this.personalDictionary && this.worddata.id !== this.dictionaryInfo!.id) {
      return true
    }

    return false
  }

  get phonicsEditView () {
    return (this.$refs.phonicsEdit as EditWordPhonics)
  }

  get dictionaryEditView () {
    return (this.$refs.dictionaryEdit as EditWordDictionary)
  }

  get thesaurusEditView () {
    return (this.$refs.thesaurusEdit as EditWordThesaurus)
  }

  get elementsEditView () {
    return (this.$refs.elementsEdit as EditWordDetails)
  }

  get detailsEditView () {
    return (this.$refs.detailsEdit as EditWordDetails)
  }
}

