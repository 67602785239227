






































































































































































import { extname } from 'path'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { sleep } from '../../utils'
import ImageInput from '../../components/ImageInput.vue'
import AudioInput from '../../components/AudioInput.vue'
import { DictionaryLanguage, getLocaleEmojiFlag, getVoiceName, getVoicesForLanguage, VoiceLocale } from '../../i18n'
import DictionaryCommon from './mixins/DictionaryCommon'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'

interface AudioLocale {
  voice: string
  underscored: VoiceLocale
  emoji: string
}

type NewFiles = Partial<{
  [K in VoiceLocale]: File | null;
}>

@Component({ components: { ImageInput, AudioInput, EditDictionaryToolbar } })
export default class EditWordDetails extends Mixins(DictionaryCommon) {
  @Prop({ default: 'en_GB' }) readonly locale!: DictionaryLanguage

  loading: boolean = false

  generatingAudio: string[] = []

  newFiles: NewFiles = {}

  get audioLocales (): AudioLocale[] {
    const filteredLocales = getVoicesForLanguage(this.dictionaryInfo.locale)
    return filteredLocales.map(l => ({
      voice: getVoiceName(l),
      underscored: l,
      emoji: getLocaleEmojiFlag(l)
    }))
  }

  mounted () {

  }

  clearImage () {
    this.dictionaryInView.image = null
    this.dictionaryInView.new_image = null
  }

  undoImageChange () {
    this.dictionaryInView.image = this.cloneDeep(this.origDictionaryInfo.image)
    this.dictionaryInView.new_image = undefined
  }

  undoAudioChange () {
    this.dictionaryInView.audio_versions = this.cloneDeep(this.origDictionaryInfo.audio_versions)
    this.dictionaryInView.new_audio_versions = undefined
    this.newFiles = {}
  }

  generateAudio (audioLocale: AudioLocale) {
    try {
      this.generatingAudio.push(audioLocale.underscored)
      const locale = audioLocale.underscored
      axios.get(`/dictionary/${this.dictionaryInfo.id}/audio`, { params: { locale }, responseType: 'blob' })
        .then((response) => {
          this.newFiles[locale] = new File([response.data], this.dictionaryInfo.word + '.mp3')
          const upload = this.newFiles[locale]
          if (!upload) {
            this.generatingAudio = this.generatingAudio.filter(a => a !== audioLocale.underscored)

            throw new Error('No file for this locale')
          }
          this.processNewFile(audioLocale, upload)
          this.generatingAudio = this.generatingAudio.filter(a => a !== audioLocale.underscored)
        }).catch((err) => {
          this.generatingAudio = this.generatingAudio.filter(a => a !== audioLocale.underscored)

          throw err
        })
    } catch (err: unknown) {
      let message = 'Could not generate the audio!'
      if (err instanceof Error) {
        message = err.message
      }
      this.$buefy.toast.open({ message, type: 'is-danger', position: 'is-bottom' })

      this.generatingAudio = this.generatingAudio.filter(a => a !== audioLocale.underscored)
    }
  }

  generateAllAudios () {
    for (const audioLocale of this.audioLocales) {
      if (!this.dictionaryInView?.audio_versions?.[audioLocale.underscored] && !this.dictionaryInView?.new_audio_versions?.[audioLocale.underscored]) {
        this.generateAudio(audioLocale)
      }
    }
  }

  uploadedAudio (audioLocale: AudioLocale) {
    try {
      const upload = this.newFiles[audioLocale.underscored]
      if (!upload) {
        throw new Error('No file for this locale!!')
      }
      this.processNewFile(audioLocale, upload)
      // this.$buefy.toast.open({ message: 'File uploaded successfully!', type: 'is-success', position: 'is-bottom' })
    } catch (err: unknown) {
      let message = 'Could not generate the audio!'
      if (err instanceof Error) {
        message = err.message
      }
      this.$buefy.toast.open({ message, type: 'is-danger', position: 'is-bottom', duration: 10000 })
    } finally {
      this.loading = false
    }
  }

  processNewFile (audioLocale: AudioLocale, newFile: File) {
    const reader = new FileReader()

    reader.readAsDataURL(newFile)

    reader.addEventListener('load', () => {
      const file = {
        data: reader.result as string,
        extname: extname(newFile.name),
        name: this.dictionaryInView.word + extname(newFile.name)
      }

      const audioVersion = {
        [audioLocale.underscored]: file
      }
      if (!this.dictionaryInView.new_audio_versions) {
        this.dictionaryInView.new_audio_versions = audioVersion
      } else {
        this.dictionaryInView.new_audio_versions = {
          ...this.dictionaryInView.new_audio_versions,
          ...audioVersion
        }
      }
    })
    if (this.dictionaryInView.audio_versions[audioLocale.underscored]) {
      this.dictionaryInView.audio_versions[audioLocale.underscored] = undefined
    }
  }

  deleteAudio (audioLocale: AudioLocale) {
    try {
      if (this.dictionaryInView.audio_versions?.[audioLocale.underscored]) {
        this.dictionaryInView.audio_versions[audioLocale.underscored] = undefined
        if (!this.dictionaryInView?.new_audio_versions) {
          this.dictionaryInView.new_audio_versions = {
            [audioLocale.underscored]: null
          }
        } else {
          this.dictionaryInView.new_audio_versions[audioLocale.underscored] = null
        }
      }
      if (this.dictionaryInView?.new_audio_versions?.[audioLocale.underscored]) {
        this.dictionaryInView.new_audio_versions[audioLocale.underscored] = this.origDictionaryInfo.audio_versions?.[audioLocale.underscored] ? null : undefined
      }
      this.newFiles[audioLocale.underscored] = null
      // this.$buefy.toast.open({ message: 'Audio deleted successfully!', type: 'is-success', position: 'is-bottom' })
    } catch (err) {
      this.$buefy.toast.open({ message: 'Could not deleted the audio!', type: 'is-danger', position: 'is-bottom' })
    }
  }

  private playAudio (audioLocale: AudioLocale) {
    try {
      this.loading = true
      let audioSrc = ''
      const dbFile = this.dictionaryInView.audio_versions?.[audioLocale.underscored]
      const newFile = this.dictionaryInView?.new_audio_versions?.[audioLocale.underscored]
      if (newFile) {
        const type = `audio/${newFile.extname.replace('.', '')}`
        const blob = new Blob([new Uint8Array(atob(newFile.data.split(',')[1]).split('').map(char => char.charCodeAt(0)))], { type })
        audioSrc = URL.createObjectURL(blob)
      } else if (dbFile && dbFile.filePath) {
        audioSrc = dbFile.filePath
      }
      if (!audioSrc) {
        throw new Error('No audio path')
      }
      const audio = new Audio(audioSrc)
      audio.oncanplaythrough = async () => {
        await sleep(250)
        audio.play()
      }
    } catch (err: unknown) {
      let message = 'Could not play the audio!'
      if (err instanceof Error) {
        message = err.message
      }
      this.$buefy.toast.open({ message, position: 'is-bottom', type: 'is-danger' })
    } finally {
      this.loading = false
    }
  }

  disablePlayButton (audioLocale: AudioLocale) {
    return !this.dictionaryInView?.audio_versions?.[audioLocale.underscored] && !this.dictionaryInView?.new_audio_versions?.[audioLocale.underscored]
  }

  disableDeleteButton (locale: VoiceLocale) {
    return !(this.dictionaryInView?.audio_versions?.[locale] || this.dictionaryInView?.new_audio_versions?.[locale])
  }

  disableGenerateButton (locale: VoiceLocale) {
    return this.dictionaryInView?.audio_versions?.[locale] || this.dictionaryInView?.new_audio_versions?.[locale] || this.generatingAudio.includes(locale)
  }

  getAudioLabel (locale: VoiceLocale) {
    return this.dictionaryInView?.new_audio_versions?.[locale]?.name || 'Upload'
  }
}
