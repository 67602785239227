




















































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DictionaryDefinition, DictionaryDefinitionClass, PhonemesInfo } from '../../api'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'
import DictionaryCommon from './mixins/DictionaryCommon'

@Component({ components: { EditDictionaryToolbar } })
export default class EditWordDictionary extends Mixins(DictionaryCommon) {
  @Prop({ default: [], required: true }) phonemesData!: PhonemesInfo[]

  wordClasses = DictionaryDefinitionClass
  word_class: DictionaryDefinitionClass | '' | null = ''

  mounted () {
    this.word_class = this.dictionaryInView.word_class ? this.dictionaryInView.word_class : ''
  }

  get isMorphemeComplete () {
    return this.dictionaryInView.morphemes.length === 0 || this.dictionaryInView.morphemes.join('') === this.dictionaryInView.word
  }

  get areDefinitionsComplete () {
    for (const definition of this.dictionaryInView.definitions) {
      if (!this.isDefinitionComplete(definition)) {
        return false
      }
    }

    return true
  }

  get areSentencesComplete () {
    for (const sentence of this.dictionaryInView.sentences) {
      if (!this.isSentenceComplete(sentence)) {
        return false
      }
    }

    return true
  }

  get isSyllableComplete () {
    return this.dictionaryInView.syllables.length === 0 || this.dictionaryInView.syllables.join('') === this.dictionaryInView.word.replace(' ', '')
  }

  get wordClassesFilter () {
    return this.wordClasses.filter(c => this.dictionaryInView.word_class ? this.dictionaryInView.word_class === c : true)
  }

  isDefinitionComplete (def: DictionaryDefinition) {
    return def.definition.trim() !== ''
  }

  isSentenceComplete (sen: string) {
    return sen.trim() !== '' && sen.includes('*')
  }

  removeSentence (ind: number) {
    this.dictionaryInView.sentences.splice(ind, 1)
  }

  addSentence () {
    this.dictionaryInView.sentences.push('')
  }

  removeDefinition (ind: number) {
    this.dictionaryInView.definitions.splice(ind, 1)
  }

  addDefinition () {
    this.dictionaryInView.definitions.push({ class: 'noun', definition: '' })
  }

  checkMorpheme (addedMorpheme: string) {
    // this is how much of the word has been constructed so far
    const wordProgress = this.dictionaryInView.morphemes.join('')

    // can the new morpheme be postpended and still match?
    return this.dictionaryInView.word.indexOf(wordProgress + addedMorpheme) === 0
  }

  checkSyllable (addedSyllable: string) {
    // this is how much of the word has been constructed so far
    const wordProgress = this.dictionaryInView.syllables.join('')

    // can the new morpheme be postpended and still match?
    return this.dictionaryInView.word.replace(' ', '').indexOf(wordProgress + addedSyllable) === 0
  }

  selectWordClass (word_class: DictionaryDefinitionClass | '') {
    if (word_class) {
      this.dictionaryInView.word_class = word_class
      for (const def of this.dictionaryInView.definitions) {
        def.class = word_class
      }
    } else {
      this.dictionaryInView.word_class = null
    }
  }
}
