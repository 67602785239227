

















import { Mixins, Component, Prop } from 'vue-property-decorator'
import ModalMixin from '../mixins/ModalMixin'

@Component({})
export default class ModalTemplate extends Mixins(ModalMixin) {
  @Prop({ default: false }) open!: boolean
  @Prop({ type: Boolean }) large!: boolean
  @Prop({ type: Boolean }) medium!: boolean
  @Prop({ default: '' }) title!: boolean
  @Prop({ type: Boolean }) closeWarning!: boolean

  public async closeModal () {
    if (this.closeWarning) {
      if (await this.confirm({ title: 'Warning', message: 'Do you wish to exit this form?' })) {
        this.close()
      }
    } else {
      this.close()
    }
  }

  public close () {
    this.$emit('close')
  }
}
