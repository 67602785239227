














import { Component, Vue, Prop } from 'vue-property-decorator'
import { GraphemePhonemePair, Locale } from '../../api'
import { LocaleData } from '../../i18n/locale-map'

@Component
export default class PhonicsPairBlock extends Vue {
@Prop({ required: true }) readonly pair!: GraphemePhonemePair
@Prop({ required: true }) readonly symbol!: string
@Prop({ default: 'en_GB' }) readonly locale!: Locale

/**
  * Function that takes a phoneme code and locale and plays the relevant audio
  */
@Prop({ required: true }) readonly playSoundForPhoneme!: (code: string, locale: LocaleData) => void
}
