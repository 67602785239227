// @ts-ignore
import { Howl } from 'howler'
import axios from 'axios'
import { Component } from 'vue-property-decorator'
import { DictionaryInfo, Locale, StoredFile } from '@/edshed-common/api'
import { StoreType } from '@/store/types'
import ComponentHelper from './ComponentHelper'

@Component
export default class SpeechHelper extends ComponentHelper {
  soundBank: any[] = []

  protected speakWord (word: string, locale: Locale): void {
    // var locale = this.session.locale
    const utterance = new SpeechSynthesisUtterance(word)
    const voices = window.speechSynthesis.getVoices()
    for (let i = 0; i < voices.length; i++) {
      if (voices[i].lang === locale || voices[i].lang === locale.replace('_', '-')) {
        utterance.voice = voices[i]
        break
      }
    }
    window.speechSynthesis.cancel()
    window.speechSynthesis.speak(utterance)
  }

  protected async playSoundForPhoneme (code: string, locale: Locale) {
    if (!code) {
      return
    }

    const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/mp3/' + code.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/PHONICS/ogg/' + code.toLowerCase() + '.ogg'

    // @ts-ignore
    await axios.get(filename, { headers: [], withCredentials: false })
    // @ts-ignore
    await axios.get(oggFile, { headers: [], withCredentials: false })

    const sound = new Howl({
      src: [oggFile, filename]
    })
    this.stopSounds()
    sound.play()
    this.soundBank.push(sound)
  }

  playAudio (audio: StoredFile) {
    try {
      const mp3File = audio.filePath
      const oggFile = audio.filePath ? audio.filePath.slice(0, -3) + 'ogg' : undefined

      if (!mp3File || !oggFile) {
        throw new Error('No audio')
      }

      const sound = new Howl({
        src: [mp3File, oggFile],
        onloaderror: () => {
          throw new Error('Cannot load audio')
        }
      })
      this.stopSounds()
      sound.play()
      this.soundBank.push(sound)
    } catch (err: unknown) {
      let message = 'Could not play the audio'
      if (err instanceof Error) {
        message = err.message
      }
      this.$buefy.toast.open({ message, position: 'is-bottom', type: 'is-danger' })
    }
  }

  protected playDictionaryAudio (dictionary: DictionaryInfo) {
    // let trap_bath = null
    let trap_bath
    if (this.$store.state.user?.school?.settings) {
      trap_bath = (this.$store as StoreType).state.user?.school?.settings
    }
    const { variant_type, variant_audio, audio_versions, word, locale } = dictionary

    if (trap_bath && variant_type === 'Trap-bath' && variant_audio) {
      try {
        this.playAudio(variant_audio)
        return
      } catch {}
    }

    const localeAudio = this.userLocale ? audio_versions[this.userLocale] : undefined
    if (localeAudio) { // user locale audio
      try {
        this.playAudio(localeAudio)
        return
      } catch {}
    }

    const wordLocaleAudio = audio_versions[locale]

    if (wordLocaleAudio) { // word locale audio
      try {
        this.playAudio(wordLocaleAudio)
        return
      } catch {}
    }

    this.playWordText(word, locale)
  }

  protected async playWordText (word: string, locale: Locale): Promise<void> {
    const filename = 'https://files.edshed.com/audio/dictionary/' + locale + '/' + word[0].toUpperCase() + '/mp3/' + word.toLowerCase() + '.mp3'
    const oggFile = 'https://files.edshed.com/audio/dictionary/' + locale + '/' + word[0].toUpperCase() + '/ogg/' + word.toLowerCase() + '.ogg'

    try {
      // @ts-ignore
      await axios.get(filename, { headers: [], withCredentials: false })
      const sound = new Howl({
        src: [oggFile, filename]
      })
      this.stopSounds()
      sound.play()
      this.soundBank.push(sound)
    } catch (err) {
      this.playGCWord(word, locale)
    }
  }

  protected playGCWord (word: string, locale: Locale): void {
    const encodedWord = encodeURIComponent(word.toLowerCase())
    const filename = 'https://api.edshed.com/audio/' + locale + '/' + encodedWord + '.mp3'
    const oggFile = 'https://api.edshed.com/audio/' + locale + '/' + encodedWord + '.ogg'

    axios.get(filename)
      .then(() => {
        axios.get(oggFile)
          .then(() => {
            const sound = new Howl({
              src: [oggFile, filename],
              onloaderror: () => {
                this.speakWord(word, locale)
              }
            })
            this.stopSounds()
            sound.play()
            this.soundBank.push(sound)
          })
          .catch(() => {
            // does not exists
            this.speakWord(word, locale)
          })
      })
      .catch(() => {
        // does not exists
        const utterance = new SpeechSynthesisUtterance(word)
        const voices = window.speechSynthesis.getVoices()

        for (let i = 0; i < voices.length; i++) {
          if (voices[i].lang === locale || voices[i].lang === locale.replace('_', '-')) {
            utterance.voice = voices[i]
            break
          }
        }

        window.speechSynthesis.speak(utterance)
      })
  }

  checkSoundBank () {
    if (!this.soundBank) {
      this.soundBank = []
    }
    // Remove sounds which are no longer playing
    this.soundBank = this.soundBank.filter(x => x.playing)
  }

  stopSounds () {
    this.checkSoundBank()

    for (const sound of this.soundBank) {
      sound.stop()
    }
  }
}
