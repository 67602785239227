/* eslint-disable no-multi-spaces */
import { GraphemePhonemePair, PhonemesInfo } from '../api'

// export const highFrequencyWordLists: {chapter: string, [key: string]: string}[] = [
//   { chapter: '2', 'en-gb': 'K3u-a6eAR' },
//   { chapter: '3', 'en-gb': 'JPDGXU26k' },
//   { chapter: '4a', 'en-gb': 'qSdllVAs9' },
//   { chapter: '4b', 'en-gb': 'd-KrsTgvh' }
// ]
export const highFrequencyWordLists: {chapter: string, set: string, subset: string, [key: string]: string}[] = [
  { chapter: '2',   set: '1',   subset: '', 'en-gb': '551TYLHvk', 'en-us': '4_mM5OZ0Eo' },
  { chapter: '2',   set: '2',   subset: '', 'en-gb': 'x1Ql2WgOI', 'en-us': '5uTHSERAji' },
  { chapter: '2',   set: '3',   subset: '', 'en-gb': 'j2s1919Ld', 'en-us': 'z12Ff8m1T9b' },
  { chapter: '2',   set: '4',   subset: '', 'en-gb': 'phY0doZjD', 'en-us': 'DW3ccj1aE4' },
  { chapter: '2',   set: '5',   subset: '', 'en-gb': 'S1oYdXBco', 'en-us': 'e4imnlTLjwz' },
  { chapter: '2',   set: '6',   subset: '', 'en-gb': 'WkFVt7SXX', 'en-us': 'CNfFhpPfy1' },
  { chapter: '2',   set: '7',   subset: '', 'en-gb': 'D5jI5San4', 'en-us': 'etU4nT9CK1' },
  { chapter: '2',   set: '8',   subset: '', 'en-gb': 'sK3tbfVk7', 'en-us': 'ITGangm9Zy' },
  { chapter: '3',   set: '1',   subset: '', 'en-gb': 'cUOHYDSvm' },
  { chapter: '3',   set: '2',   subset: '', 'en-gb': 'PV3IrpmBV', 'en-us': 'XVJphA7cww' },
  { chapter: '3',   set: '3',   subset: '', 'en-gb': '9nnunVnq7', 'en-us': 'imSLswNF7l' },
  { chapter: '3',   set: '4',   subset: '', 'en-gb': '12_Isq0iM', 'en-us': '5Lh9AYIYyF' },
  { chapter: '3',   set: '5',   subset: '', 'en-gb': 'OI4JXsA1B', 'en-us': 'SnlkxmvbpUa' },
  { chapter: '3',   set: '6',   subset: '', 'en-us': 'KaDeZX6hhQ' },
  { chapter: '3',   set: '7',   subset: '', 'en-us': 'DtDe8nX1S_' },
  { chapter: '3',   set: '8',   subset: '', 'en-us': 'XbmXg6A_OC' },
  { chapter: '3',   set: '9',   subset: '', 'en-us': 'wGb-pd9x9i' },
  { chapter: '3',   set: '10',  subset: '', 'en-us': '-qNjRD7PMDa' },
  { chapter: '3',   set: '12',  subset: '', 'en-us': '8RSQeNMxgq' },
  { chapter: '3',   set: '13',  subset: '', 'en-us': '3vrxAxxx2GX' },
  { chapter: '4a',  set: '1',   subset: '', 'en-gb': 'mEIFg4AO-' },
  { chapter: '4a',  set: '3',   subset: '', 'en-gb': 'KGs-e9Xfj' },
  { chapter: '4a',  set: '4',   subset: '', 'en-gb': 'ukqBD3SyL' },
  { chapter: '4a',  set: '5',   subset: '', 'en-gb': 'p32jz6U3_' },
  { chapter: '4b',  set: '2',   subset: '', 'en-gb': '3oUxPBaM2' },
  { chapter: '4b',  set: '3',   subset: '', 'en-gb': '9b_svqbtU' },
  { chapter: '4b',  set: '4',   subset: '', 'en-gb': 'mu8zPFxg6' },
  { chapter: '4b',  set: '5',   subset: '', 'en-gb': 'jqHra99oD' },
  { chapter: '4b',  set: '6',   subset: '', 'en-gb': 'dm1UPwjnq' },
  { chapter: '4b',  set: '7',   subset: '', 'en-gb': '_hs9vAKyC' },
  { chapter: '4',   set: '3',   subset: '', 'en-us': 'Wq8kJcxjBl' },
  { chapter: '4',   set: '4',   subset: '', 'en-us': 'rKpAsa7YFo' },
  { chapter: '4',   set: '5',   subset: '', 'en-us': 'AX0wLuCPmn' },
  { chapter: '4',   set: '6',   subset: '', 'en-us': '5gmj5NuhZT' },
  { chapter: '4',   set: '7',   subset: '', 'en-us': 'SZ2J7bpJ78' },
  { chapter: '4',   set: '8',   subset: '', 'en-us': 'W3ouSmkwOR' },
  { chapter: '4',   set: '9',   subset: '', 'en-us': '5SmwuJhPq0' },
  { chapter: '4',   set: '10',  subset: '', 'en-us': '-FWZ-f4u8z' },
  { chapter: '1',   set: '1',   subset: '1', 'en-za': '551TYLHvk' },
  { chapter: '1',   set: '1',   subset: '3', 'en-za': 'x1Ql2WgOI' },
  { chapter: '1',   set: '1',   subset: '5', 'en-za': 'j2s1919Ld' },
  { chapter: '1',   set: '1',   subset: '7', 'en-za': 'phY0doZjD' },
  { chapter: '1',   set: '1',   subset: '9', 'en-za': 'S1oYdXBco' },
  { chapter: '1',   set: '2',   subset: '2', 'en-za': 'WkFVt7SXX' },
  { chapter: '1',   set: '2',   subset: '4', 'en-za': 'D5jI5San4' },
  { chapter: '1',   set: '3',   subset: '2', 'en-za': 'sK3tbfVk7' },
  { chapter: '1',   set: '3',   subset: '5', 'en-za': 'cUOHYDSvm' },
  { chapter: '1',   set: '3',   subset: '7', 'en-za': 'PV3IrpmBV' },
  { chapter: '1',   set: '3',   subset: '9', 'en-za': '9nnunVnq7' },
  { chapter: '1',   set: '4',   subset: '3', 'en-za': '12_Isq0iM' },
  { chapter: '1',   set: '4',   subset: '5', 'en-za': 'OI4JXsA1B' },
  { chapter: '2',   set: '1',   subset: '2', 'en-za': 'mEIFg4AO-' },
  { chapter: '2',   set: '1',   subset: '6', 'en-za': 'KGs-e9Xfj' },
  { chapter: '2',   set: '1',   subset: '8', 'en-za': 'ukqBD3SyL' },
  { chapter: '2',   set: '2',   subset: '2', 'en-za': 'p32jz6U3_' },
  { chapter: '2',   set: '2',   subset: '5', 'en-za': '3oUxPBaM2' },
  { chapter: '2',   set: '2',   subset: '8', 'en-za': '9b_svqbtU' },
  { chapter: '2',   set: '3',   subset: '1', 'en-za': 'mu8zPFxg6' },
  { chapter: '2',   set: '3',   subset: '4', 'en-za': 'jqHra99oD' },
  { chapter: '2',   set: '3',   subset: '7', 'en-za': 'dm1UPwjnq' },
  { chapter: '2',   set: '4',   subset: '1', 'en-za': '_hs9vAKyC' }
]

export const usScreeningCheckLists: {chapter: string, alien: boolean, ident: string }[] = [
  { chapter: '2', alien: false, ident: 'g76e-R8kZ' },
  { chapter: '2', alien: true, ident: 'YY5rUDgm1' },
  { chapter: '3', alien: false, ident: 'gdxNahNrd' },
  { chapter: '3', alien: true, ident: '-eCAy13aT' },
  { chapter: '4', alien: false, ident: 'QcnwgqWuK' }
]

export const gbScreeningCheckLists: {chapter: string, ident: string }[] = [
  { chapter: '2011', ident: 'NlAnRUxoyiLlBA' },
  { chapter: '2012', ident: 'lDn63SUbgathWl' },
  { chapter: '2013', ident: '6RlXBrhndLAQBx' },
  { chapter: '2014', ident: 'tUXQ7GWeHSBhHH' },
  { chapter: '2015', ident: 'nECgS6PfyLgEHh' },
  { chapter: '2016', ident: 'ZCjGuYxPysOAcK' },
  { chapter: '2017', ident: 'u7Tsq6gWA_IYKM' },
  { chapter: '2018', ident: '6shr5MNVtq5SGh' },
  { chapter: '2019', ident: '4Uyrb3YCVgQ9NL' },
  { chapter: '2022', ident: 'qz-I5RcefaPXtd' },
  { chapter: '2023', ident: 'Pt7YQ6he9qAuxX' },
  { chapter: '2024', ident: 'F7nhfgI-xKmnkM' }
]

export function getHighFrequencyWordListsForLocale (locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  const lists = highFrequencyWordLists.filter(x => x[l])
  return lists.map((x) => {
    return {
      chapter: x.chapter,
      set: parseInt(x.set),
      subset: parseInt(x.subset) || undefined,
      list_ident: x[l]
    }
  })
}

export function getHighFrequencyWordListsForChapter (chapter: string, locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  return getHighFrequencyWordListsForLocale(l).filter(x => x.chapter === chapter)
}

export function getChapterForHighFrequencyWordListForLocale (list_ident: string, locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  const item = highFrequencyWordLists.find(x => x[l] === list_ident)
  if (item) {
    return item.chapter
  }
  return null
}
export function getSetForHighFrequencyWordListForLocale (list_ident: string, locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  const item = highFrequencyWordLists.find(x => x[l] === list_ident)
  if (item) {
    return parseInt(item.set)
  }
  return null
}

export function getSubsetForHighFrequencyWordListForLocale (list_ident: string, locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  const item = highFrequencyWordLists.find(x => x[l] === list_ident)
  if (item) {
    return parseInt(item.subset) || null
  }
  return null
}

export function getElementsForLocale (locale?: string) {
  const l = getAcceptableLocale(locale || 'en-gb')
  return [
    {
      id: 1,
      title: 'Environmental Sounds'
    },
    {
      id: 2,
      title: 'Motor Skills'
    },
    {
      id: 3,
      title: 'Our Bodies'
    },
    {
      id: 4,
      title: 'Instrumental Sounds'
    },
    {
      id: 5,
      title: 'Our Voices'
    },
    {
      id: 6,
      title: 'Stories, Rhymes and Rhythm'
    },
    {
      id: 7,
      title: 'From Left to Right'
    },
    {
      id: 8,
      title: 'Initial Sounds'
    },
    {
      id: 9,
      title: 'Beginning to Blend and Segment'
    }
  ]
}

export const allGraphemes = ['f|f', 'ff|f', 'ph|f', 'l|l', 'll|l', 'le|l', 'm|m', 'mm|m', 'mb|m', 'n|n', 'nn|n', 'kn|n', 'r|r', 'rr|r', 'wr|r', 's|s', 'ss|s', 'se|s', 'c|s', 'ce|s', 'v|v', 've|v', 'z|z', 'zz|z', 's|z', 'se|z', 'sh|sh', 'ti|sh', 'ci|sh', 'th|th', 'th|th-hard', 'ng|ng', 'b|b', 'bb|b', 'c|k', 'k|k', 'ck|k', 'ch|k', 'd|d', 'dd|d', 'g|g', 'gg|g', 'h|h', 'j|j', 'j|zh', 'g|j', 'g|zh', 'ge|j', 'ge|zh', 'dge|j', 'p|p', 'pp|p', 'qu|q', 'qu|q', 't|t', 'tt|t', 'w|w', 'wh|w', 'x|x', 'x|gz', 'y|y', 'ch|ch', 'tch|ch', 'a|a', 'e|e', 'e|i', 'ea|e', 'i|i', 'o|o', 'o|u', 'u|u', 'oo|oo', 'ay|a-long', 'a_e|a-long', 'ai|a-long', 'ee|e-long', 'y|e-long', 'ea|e-long', 'e|e-long', 'igh|i-long', 'i_e|i-long', 'ie|i-long', 'i|i-long', 'y|i-long', 'ow|o-long', 'o_e|o-long', 'oa|o-long', 'o|o-long', 'oo|oo-long', 'e_e|e-long', 'u_e|u-long', 'ue|u-long', 'ue|oo-long', 'ew|u-long', 'ew|oo-long', 'oo|u', 'ar|ar', 'or|or', 'oor|or', 'ore|or', 'aw|or', 'au|or', 'air|air', 'are|air', 'ear|air', 'ear|ear', 'ir|ir', 'ur|ir', 'er|ir', 'ou|ow', 'ow|ow', 'oy|oi', 'oi|oi', 'a|schwa', 'er|schwa', 'i|schwa', 'ar|schwa', 'our|schwa', 'ur|schwa', '-|_', '\'|_', 'ure|ure']

export function symbolForPhoneme (code: string, phonemes: PhonemesInfo[]) {
  if (!code || !phonemes) {
    return null
  }

  return phonemes.find(a => a.code === code)?.ipa ?? '?'
}

export function findPhonemesForGrapheme (g: string, phonemes: PhonemesInfo[]) {
  const compatiblePhonemes = allGraphemes.filter(s => s.split('|')[0] === g).map(s => s.split('|')[1])
  const allWithCompatibility = phonemes.map(e => ({ ...e, compatible: compatiblePhonemes.includes(e.code) }))
  return allWithCompatibility.sort((a, b) => {
    return a.compatible < b.compatible ? 1 : a.compatible > b.compatible ? -1 : a.code > b.code ? 1 : a.code < b.code ? -1 : 0
  })
}

export function getPhonologicalStructureOfWord (pairs: GraphemePhonemePair[]) {
  return pairs.filter(p => p.grapheme.trim() !== '').map(p => ['a', 'e', 'i', 'o', 'u'].includes(p.grapheme[0]) ? 'V' : 'C').join(' ')
}

export function getOrthographicalStructureOfWord (pairs: GraphemePhonemePair[]) {
  let skip = false
  return pairs.filter(p => p.grapheme.trim() !== '').map((p, i) => {
    function getLetter (grapheme: string): string {
      // treat qu as special case consonant digraph
      if (grapheme === 'qu') {
        return 'CC'
      }

      const letters = grapheme.split('')
      return letters.map((l, j) => {
        // handle split digraphs
        if (l === '_') {
          skip = true
          if (pairs[i + 1]) {
            return getLetter(pairs[i + 1].grapheme.toLowerCase())
          }
        }

        if (['a', 'e', 'i', 'o', 'u'].includes(l)) {
          return 'V'
        }

        // treat R-controlled words as vowel digraphs
        if (l === 'r' && grapheme.length === 2 && ['a', 'e', 'i', 'o', 'u'].includes(letters[j - 1])) {
          return 'V'
        }

        // handle vowel digraphs that contain consonants (e.g. 'ey', 'ow', 'igh')
        if (l === 'y' && grapheme.length === 2 && ['a', 'e', 'i', 'o', 'u'].includes(letters[j - 1])) {
          return 'V'
        }

        if (l === 'w' && grapheme.length === 2 && ['a', 'e', 'i', 'o', 'u'].includes(letters[j - 1])) {
          return 'V'
        }

        if (l === 'g' && grapheme.length === 3 && letters[j - 1] && letters[j + 1] && ['a', 'e', 'i', 'o', 'u'].includes(letters[j - 1]) && letters[j + 1] === 'h') {
          return 'V'
        }

        if (l === 'h' && grapheme.length === 3 && letters[j - 1] && letters[j - 2] && ['a', 'e', 'i', 'o', 'u'].includes(letters[j - 2]) && letters[j - 1] === 'g') {
          return 'V'
        }

        return 'C'
      }).join('')
    }
    if (skip) {
      skip = false
      return null
    }

    return getLetter(p.grapheme.toLowerCase())
  }).filter(e => e !== null).join(' ')
}

function getAcceptableLocale (locale: string) {
  if (locale && localeHasHighFrequencyWordLists(locale)) {
    return locale
  }
  return 'en-gb'
}

export function localeHasHighFrequencyWordLists (locale: string) {
  return !!highFrequencyWordLists.filter(x => x[locale]).length
}
