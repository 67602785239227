










































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { symbolForPhoneme } from '@/edshed-common/phonics/index'
import { Api, DictionaryInfo, PhonemesInfo } from '../../api'
import { DictionaryLanguage, LocaleData } from '../../i18n/locale-map'
import PhonicsPairBlock from './PhonicsPairBlock.vue'
import EditDictionaryToolbar from './EditDictionaryToolbar.vue'

@Component({ components: { PhonicsPairBlock, EditDictionaryToolbar } })
export default class WordVariantSelector extends Vue {
  @Prop({ required: true }) readonly word!: string
  @Prop({ default: 'en_GB' }) readonly wordLocale!: DictionaryLanguage
  @Prop({ default: null }) readonly dictionaryId!: number | null
  @Prop({ default: 'is-large' }) readonly customIconSize!: string
  @Prop({ default () { return [] } }) readonly homographs!: DictionaryInfo[]
  @Prop({ default: false }) readonly allowCreate!: boolean
  @Prop({ required: true }) readonly phonemesData!: PhonemesInfo[]

  /**
   * Function that checks equality between values, capable of considering nested objects. Recommend using lodash's isEqual
   */
  @Prop({ required: true }) readonly isEqual!: (obj: any, obj2: any) => boolean

  /**
  * Function that takes a phoneme code and locale and plays the relevant audio
  */
  @Prop({ required: true }) readonly playSoundForPhoneme!: (code: string, locale: LocaleData) => void

  /**
  * Function that takes a DictionaryInfo object and plays the relevant audio
  */
  @Prop({ required: true }) readonly playDictionaryAudio!: (d: DictionaryInfo) => void

  private selectedDefinition: DictionaryInfo | null = null

  private dictionaryMoreDetails: DictionaryInfo | null = null

  public symbolForPhoneme = symbolForPhoneme

  async mounted () {
    if (this.homographs.length === 0) {
      const loadedHomographs = await Api.getHomographsOfWord(this.word, this.wordLocale)

      this.homographs.splice(0, 0, ...loadedHomographs.filter(h => !h.hidden))
    }

    if (this.dictionaryId) {
      this.selectedDefinition = this.homographs.find(h => h.id === this.dictionaryId) || null
    } else { // new word
      this.selectedDefinition = this.homographs.find(h => h.word === this.word) || null
    }
  }

  get officialHomographs () {
    return this.homographs.filter(r => r.user_id === 1 && r.parent_word === null)
  }

  get orderedHomographs () {
    const array: DictionaryInfo[] = []

    for (const h of this.officialHomographs) {
      array.push(h)
      array.splice(array.length, 0, ...this.homographs.filter(e => e.parent_word && e.parent_word.id === h.id))
    }

    return array
  }

  get wordNotFoundError () {
    let error = `You entered <strong>"${this.word}"</strong>, but we could not find it in our dictionary. Please check our suggestions below.`

    if (this.allowCreate) {
      error += ` If the word you are trying to add is not there, and you are sure you have ${this.$t('spelt')} it correctly, we can add it to our dictionary for you.`
    }

    return error
  }

  moreDetailsClicked (d: DictionaryInfo) {
    this.dictionaryMoreDetails = this.dictionaryMoreDetails === d ? null : d
  }

  doesFieldMatchRoot (dictionary: DictionaryInfo, field: keyof DictionaryInfo) {
    if (dictionary.parent_word === null) {
      return true
    }

    return this.isEqual(dictionary[field], dictionary.parent_word[field])
  }

  submit () {
    if (this.selectedDefinition?.id === this.dictionaryId) {
      return true
    }

    this.$emit('selected-dictionary', this.selectedDefinition)
  }
}
